<template>
    <div class="galleryDetail">
        <img class="bg-img" :src="bannerInfo[0]">
        <div class="chain-router">
			<router-link to="/">首页</router-link>
			<em>></em>
			<span class="active">在线免费图库</span>
		</div>
        <div class="content-cls">
            <div class="main-title">
                <div class="big-title">免费图库</div>
                <div class="small-title">—   Online free gallery   —</div>
            </div>
             <div v-html="data.detail"></div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
export default {
    name: '',
    data() { 
        return {
            id: '', // 当前图库Id
            data:{},
        }
    },
    components:{
    },
    computed: {
        ...mapState({
            bannerInfo: state => state.bannerInfo[6], // banner图
        })
    },
    created() {
        this.id = this.$route.params.id;
        this.getData();
    },
    mounted() {

    },
    methods:{
        // 获取数据
        getData() {
            this.$api.getGalleryDetail({
                id: this.id
            }).then(res => {
                if (res.code == '0000') {
                    this.data = res.data;
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
    .galleryDetail{
        .bg-img{
            margin-bottom: 20px;
        }
        .main-title{
            margin-top: 50px;
            margin-bottom: 69px;
        }
    }
</style>